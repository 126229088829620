import { PackageGroupFulfillmentStatus } from '../store/config/enums/warehouseWork.enum';
import { PackageGroup } from '../store/config/types';
import {
  AssemblyBuild,
  FulfillmentItem,
  FulfillmentSalesOrderStruct,
  ItemPendingFulfillmentStruct,
  WorkorderUpdateStruct,
} from '../store/config/types/warehouseWork.types';
import { packageGroupToFulfillmentSalesOrder } from '../utils/fulfillment.helpers';
import axios from './axios';

function filterToQuery(filters: any) {
  let query: string = '';
  Object.keys(filters)
    .filter((filterName) => filters[filterName])
    .forEach((filterName, index) => {
      query += `${index == 0 ? '?' : '&'}${filterName}=${filters[filterName]}`;
    });
  return encodeURI(query);
}

async function fetchItemsPendingFulfillment(filters: any): Promise<ItemPendingFulfillmentStruct[]> {
  const res = await axios.get(encodeURI(`/warehouse-work/items-pending-fulfillment${filterToQuery(filters)}`));
  return res.data.data as ItemPendingFulfillmentStruct[];
}

async function fetchFulfillmentOrders(date: string) {
  const res = await axios.get(`/warehouse-work/sales-order/${date}`);
  const salesOrderPendingFulfillment: FulfillmentSalesOrderStruct[] = [];
  const salesOrderStatusTBD: FulfillmentSalesOrderStruct[] = [];

  res.data.data.forEach((packageGroup: PackageGroup) => {
    packageGroup.orderItems?.sort((a, b) => +(a.additionalData?.lineIndex ?? 0) - +(b.additionalData?.lineIndex ?? 0));
    switch (packageGroup.fulfillment?.status) {
      case PackageGroupFulfillmentStatus.FULFILLED:
      case PackageGroupFulfillmentStatus.EXTERNALLY_FULFILLED:
      case PackageGroupFulfillmentStatus.PENDING:
      case PackageGroupFulfillmentStatus.COMMITTED:
      case PackageGroupFulfillmentStatus.PROCESSING:
      case PackageGroupFulfillmentStatus.SYNCED:
      case PackageGroupFulfillmentStatus.NEW:
        salesOrderPendingFulfillment.push(packageGroupToFulfillmentSalesOrder(packageGroup));
        break;
      default:
        salesOrderStatusTBD.push(packageGroupToFulfillmentSalesOrder(packageGroup));
    }
  });
  return { salesOrderPendingFulfillment, salesOrderStatusTBD };
}

async function fetchAssembliesPendingBuild(filters: any): Promise<ItemPendingFulfillmentStruct[]> {
  const res = await axios.get(encodeURI(`/warehouse-work/assemblies-pending-build${filterToQuery(filters)}`));
  return res.data.data as ItemPendingFulfillmentStruct[];
}

// DEPRICATED
async function changeDefaultLot(allWorkOrderIds: number[], productId: string | number, inventoryLotQuantityId: number) {
  const res = await axios.patch(encodeURI(`/warehouse-work/default-lot`), {
    allWorkOrderIds,
    productId,
    inventoryLotQuantityId,
  });
  return res.data.data;
}

async function skipFulfillment(packageGroupId: number) {
  const res = await axios.patch(`/warehouse-work/sales-order/skip`, { packageGroupId });
  return res.data.data;
}

async function convertFulfillment(packageGroupId: number[], convertTo: string) {
  const res = await axios.patch(`/warehouse-work/sales-order/convert`, { packageGroupId, convertTo });
  return res.data.data;
}
async function syncFulfillment(packageGroupId: number) {
  const res = await axios.patch(`/warehouse-work/sales-order/sync`, { packageGroupId });
  return res.data;
}

async function syncWorkOrder(assemblyBuildId: number) {
  const res = await axios.patch(`/warehouse-work/assembly-build-workorders/sync`, { assemblyBuildId });
  return res.data;
}

async function convertToPending(assemblyBuildId: number | number[]) {
  const res = await axios.patch(`/warehouse-work/assembly-build/convert`, { assemblyBuildId, convertTo: 'PENDING' });
  return res.data;
}

async function getAvailableLotsFor(productId: number, warehouseIds: number[], minQuantity: number) {
  const res = await axios.get(`/warehouse-work/available-lots/${productId}?min=${minQuantity}`);

  return res.data.data;
}

async function updatePackageGroupLots(packageGroupFulfillmentId: number, fulfillmentItems?: FulfillmentItem[]) {
  const res = await axios.patch(encodeURI(`/warehouse-work/fulfillment-items`), {
    packageGroupFulfillmentId,
    fulfillmentItems,
  });
  return res.data.data;
}

async function updateWorkorderLots(updates: WorkorderUpdateStruct[]) {
  console.log(updates);

  const res = await axios.patch(encodeURI(`/warehouse-work/lots`), updates);
  return res.data.data;
}

async function fetchAssemblyBuildWorkorders(filters: any): Promise<AssemblyBuild[]> {
  const res = await axios.get(encodeURI(`/warehouse-work/assembly-build-workorders${filterToQuery(filters)}`));
  return res.data.data as AssemblyBuild[];
}

async function createAssemblyWorkorder(
  warehouseId: number,
  productId: number,
  assemblyCount: number,
  lotId: string,
  date: string,
) {
  const res = await axios.post(encodeURI(`/warehouse-work/assembly-build-workorders`), {
    warehouseId,
    productId,
    assemblyCount,
    lotId,
    date,
  });
  return res.data.data;
}

async function changeLotOfAssemblyItem(assemblyBuildItemIds: number[], lotId: string) {
  const res = await axios.patch(encodeURI(`/warehouse-work/assembly-build-item`), {
    assemblyBuildItemIds,
    lotId,
  });
  return res.data.ok;
}

async function getAssemblyTotalQuantities(productId: number, date?: string) {
  const res = await axios.get(
    encodeURI(`/warehouse-work/assembly-build/${productId}/quantities${date ? '?date=' + date : ''}`),
  );
  return res.data.data;
}

async function getWmsUsers() {
  const res = await axios.get(encodeURI(`/warehouse-work/users`));
  return res.data.data;
}
async function saveWmsUser(user: any) {
  const res = await (user.warehouseUserId ? axios.patch : axios.post)(`/warehouse-work/users`, user);
  return res.data.data;
}
async function removeWmsUser(user: any) {
  const res = await axios.delete(`/warehouse-work/users/${user.warehouseUserId}`);
  return res.data.data;
}

async function getApiQueueInfo() {
  const res = await axios.get(`/warehouse-work/api-queue-info`);
  return res.data.data;
}

export const FulfillmentService = {
  fetchItemsPendingFulfillment,
  fetchFulfillmentOrders,
  changeDefaultLot,
  updatePackageGroupLots,
  skipFulfillment,
  convertFulfillment,
  syncFulfillment,
  getAvailableLotsFor,
};

export const AssemblyBuildService = {
  fetchAssemblyBuildWorkorders,
  fetchAssembliesPendingBuild,
  createAssemblyWorkorder,
  changeLotOfAssemblyItem,
  syncWorkOrder,
  getAssemblyTotalQuantities,
  convertToPending,
};

const WarehouseWorkService = {
  ...FulfillmentService,
  ...AssemblyBuildService,
  updateWorkorderLots,
  getWmsUsers,
  saveWmsUser,
  removeWmsUser,
  getApiQueueInfo,
};
export default WarehouseWorkService;
