import { useEffect, useState, Fragment, SyntheticEvent, useRef } from 'react';
import { connect, ConnectedProps } from 'react-redux';
// import { ThunkDispatch } from 'redux-thunk';
import moment from 'moment-timezone';
import { AssemblyBuild, AssemblyBuildItem, Product, RootState } from '../../store/config/types';
// import { SortProperties } from '../../store/config/types/deliveryOrders.types';

import { ThunkDispatch } from 'redux-thunk';
import { setToast } from '../../store/actions/toast.actions';

import { Chip, CircularProgress, Grid, IconButton, Link, Tab, Tabs, Typography } from '@mui/material';

import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import CardBody from '../../components/Card/CardBody';

import { AccessTime, BlurCircular, Brightness1, Brightness5, Refresh, Sync, Warning } from '@material-ui/icons';
import InfoCard from '../../components/InfoCard/InfoCard';
import { AssemblyBuildStatus, AssemblyBuildStatusReadableStatus } from '../../store/config/enums/warehouseWork.enum';
import { SearchBox, SelectableFiltersStruct } from '../../components/SearchBox/SearchBox';
import { AssemblyBuildService } from '../../services/warehouseWork.service';

import ChangeLotOfAssemblyItemDialog from './ChangeLotOfAssemblyBuildItemDialog';
import FilterableTable from '../../components/FilterableTable/FilterableTable';
import { strOrAlt } from '../../utils/string.helpers';
import { AssemblyBuildDetails } from './AssemblyBuildDetails';
import ItemPendingBuildTable from './ItemPendingBuildTable';
import LotsDialog from '../Fulfillment/LotsDialog';
import WorkOrderAction from './WorkOrderAction';

/* eslint-disable */
const PAGE_ITEMS: number = 12;
const REFRESH_MILISECONDS = 60000;

enum PageModes {
  ASSEMBLY_BUILD_WORKORDER_OK = 'Assembly Build Workorders',
  ASSEMBLY_BUILD_WORKORDER_TBD = 'Assembly Build Workorders TBD',
  ASSEMBLIES_PENDING_PRODUCTION = 'Assemblies Pending Production',
}

const Icons = {
  [AssemblyBuildStatus.NEW]: <AccessTime />,
  [AssemblyBuildStatus.PENDING_API]: <AccessTime />,
  [AssemblyBuildStatus.PENDING]: <AccessTime />,
  [AssemblyBuildStatus.PROCESSING]: <BlurCircular />,
  [AssemblyBuildStatus.BUILT]: <Brightness1 />,
  [AssemblyBuildStatus.PARTIAL_BUILT]: <Brightness5 />,
  [AssemblyBuildStatus.INSUFFICIENT_INVENTORY]: <Warning />,
  [AssemblyBuildStatus.AUTOLOT_FAILED]: <Warning />,
  [AssemblyBuildStatus.SYNCED]: <Sync />,
};

const okStatuses = [
  AssemblyBuildStatus.BUILT,
  AssemblyBuildStatus.PARTIAL_BUILT,
  AssemblyBuildStatus.PENDING,
  AssemblyBuildStatus.PROCESSING,
  AssemblyBuildStatus.SYNCED,
];
/* xxxeslint-enable */

const mapStateToProps = (state: RootState) => {
  return {
    auth: state.auth,
    loggedIn: state.auth.loggedIn,
  };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  setToast: (message: string, messageType: string) => dispatch(setToast(message, messageType)),
});
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function InventoryManagement({ auth, loggedIn, setToast }: PropsFromRedux) {
  if (auth && auth.account && auth.account.shipper?.timezone) moment.tz.setDefault(auth.account.shipper.timezone);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState<string>('');
  const [creationDate, setCreationDate] = useState<string>(moment().format('YYYY-MM-DD'));
  const [shippingDate, setShippingDate] = useState<string>(moment().format('YYYY-MM-DD'));
  const [selectableFilters, setSelectableFilters] = useState<SelectableFiltersStruct>({});

  const [assemblyBuildsOk, setAssemblyBuildsOk] = useState<AssemblyBuild[]>([]);
  const [assemblyBuildsTBD, setAssemblyBuildsTBD] = useState<AssemblyBuild[]>([]);
  const [assemblyBuildItems, setAssemblyBuildItems] = useState<AssemblyBuildItem[]>([]);
  const [assemblyBuildItemsToEditLots, setAssemblyBuildItemsToEditLots] = useState<AssemblyBuildItem[]>();
  const [productItem, setProductItem] = useState<Product>();

  const [loading, setLoading] = useState(false);
  const [needsRefresh, setNeedsRefresh] = useState<boolean>(false);
  const timerRef = useRef<NodeJS.Timeout>();
  const [lastRefetch, setLastRefetch] = useState<Date>(new Date());

  const [pageMode, setPageMode] = useState<PageModes>(PageModes.ASSEMBLY_BUILD_WORKORDER_OK);

  // const [sortProps, setSortProps] = useState<SortProperties>({ field: 'name', order: 'DESC' });
  const [itemToChangeLot, setItemToChangeLot] = useState<AssemblyBuildItem>();

  const [tabValue, setTabValue] = useState(0);

  const [statuses, setStatuses] = useState<any>({
    [AssemblyBuildStatus.PENDING]: 0,
    [AssemblyBuildStatus.INSUFFICIENT_INVENTORY]: 0,
    [AssemblyBuildStatus.PROCESSING]: 0,
    [AssemblyBuildStatus.BUILT]: 0,
    [AssemblyBuildStatus.PENDING_API]: 0,
    [AssemblyBuildStatus.SYNCED]: 0,
    [AssemblyBuildStatus.AUTOLOT_FAILED]: 0,
  });

  const handleTabChange = (event: SyntheticEvent, value: number) => {
    setTabValue(value);
    setPageMode(
      [
        PageModes.ASSEMBLY_BUILD_WORKORDER_OK,
        PageModes.ASSEMBLY_BUILD_WORKORDER_TBD,
        PageModes.ASSEMBLIES_PENDING_PRODUCTION,
      ][value],
    );
  };

  const handleOperation = (op: string, params: any) => {
    console.log(op, params);

    if (op == 'change-lot-of-assembly-build-item') {
      setItemToChangeLot(params);
    } else if (op == 'edit-assembly-build-items-lot') {
      setProductItem(params.product);
      setAssemblyBuildItemsToEditLots(params.assemblyBuildItems);
    } else if (op == 'update-assembly-items') {
      // refetchData();
      setNeedsRefresh(true);
    } else if (op == 'sync-with-esm') {
      setLoading(true);
      AssemblyBuildService.syncWorkOrder(params.assemblyBuildId)
        .then((result) => {
          console.log(result);

          if (result.result) {
            refetchData();
            setToast('Successfully synced', 'success');
          } else {
            const errorString: string = result.data.errors.join('\n');
            setToast(errorString, 'danger');
          }
        })
        .catch((err) => {
          setToast('Sync failed', 'danger');
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (op == 'convert-to-pending') {
      setLoading(true);
      AssemblyBuildService.convertToPending(params.assemblyBuildId)
        .then((result) => {
          if (result) {
            const errCount = result.failedIds?.length ?? 0;
            const sucCount = result.successfulIds?.length ?? 0;
            if (errCount === 0) {
              setToast('Successfully done', 'success');
            } else if (sucCount > 0) {
              setToast('Some Failed', 'warning');
            } else {
              setToast('All Failed', 'danger');
            }
          }
          refetchData();
        })
        .catch((err) => {
          setToast('Sync failed', 'danger');
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.log({ op, params });
    }
  };

  const handleChangeLotDialogClose = () => {
    setItemToChangeLot(undefined);
    setNeedsRefresh(true);
  };

  function handleAssemblyIemLotsClose(): void {
    setAssemblyBuildItemsToEditLots(undefined);
    // fetchItemsPendinfFulfillment();
  }
  // const handleSortClick = (field: string) => {
  //   if (sortProps.field === field) {
  //     setSortProps({ ...sortProps, order: sortProps.order === 'ASC' ? 'DESC' : 'ASC' });
  //   } else {
  //     setSortProps({ ...sortProps, field });
  //   }
  // };

  const fetchWorkOrders = async () => {
    if (auth && auth.account && auth.account.shipper?.timezone) moment.tz.setDefault(auth.account.shipper.timezone);
    if (auth.account?.shipper?.shipperId) {
      return AssemblyBuildService.fetchAssemblyBuildWorkorders({ creationDate }).then((fechedAssembliyBuilds) => {
        const statusesCount: any = {
          [AssemblyBuildStatus.PENDING]: 0,
          [AssemblyBuildStatus.INSUFFICIENT_INVENTORY]: 0,
          [AssemblyBuildStatus.PROCESSING]: 0,
          [AssemblyBuildStatus.BUILT]: 0,
          [AssemblyBuildStatus.NEW]: 0,
          [AssemblyBuildStatus.PENDING_API]: 0,
          [AssemblyBuildStatus.SYNCED]: 0,
          [AssemblyBuildStatus.AUTOLOT_FAILED]: 0,
        };
        fechedAssembliyBuilds.forEach((assemblyBuild, abIndex) => {
          fechedAssembliyBuilds[abIndex].readableStatus =
            AssemblyBuildStatusReadableStatus[fechedAssembliyBuilds[abIndex].status];
          statusesCount[assemblyBuild.status] = statusesCount[assemblyBuild.status] + 1;
        });
        setStatuses(statusesCount);
        setAssemblyBuilds(fechedAssembliyBuilds);
      });
    }
  };

  const refetchData = async () => {
    setNeedsRefresh(false);
    if (!loading) {
      if (timerRef.current) {
        try {
          clearTimeout(timerRef.current);
          timerRef.current = setTimeout(() => refetchData(), REFRESH_MILISECONDS);
        } catch (err) {
          console.error(err);
        }
      }
      setLastRefetch(new Date());
      setLoading(true);
      fetchWorkOrders()
        .then(() => {
          //
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    setNeedsRefresh(true);
  }, [loggedIn, auth.account]);

  useEffect(() => {
    if (needsRefresh && !loading) {
      refetchData();
    }
  }, [needsRefresh]);

  function setAssemblyBuilds(assemblyBuilds: AssemblyBuild[]) {
    if (assemblyBuilds?.length) {
      const items: AssemblyBuildItem[] = [];
      assemblyBuilds.forEach((ab) => {
        items.push(...(ab.items?.map((abItem) => ({ ...abItem, additionalData: ab.additionalData })) ?? []));
      });
      items.sort((i1, i2) => i1.assemblyBuildItemId - i2.assemblyBuildItemId);
      setAssemblyBuildItems(items);
    } else {
      setAssemblyBuildItems([]);
    }
    setAssemblyBuildsOk([...assemblyBuilds.filter((ab) => okStatuses.includes(ab.status))]);
    setAssemblyBuildsTBD([...assemblyBuilds.filter((ab) => !okStatuses.includes(ab.status))]);
  }

  useEffect(() => {
    timerRef.current = setTimeout(refetchData, REFRESH_MILISECONDS);
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  const handlePageClick = (page: number) => setCurrentPage(page);

  const handleFilterChange = (filters: any) => {
    setCreationDate(filters.creationDate);
    setShippingDate(filters.shippingDate);
    setNeedsRefresh(true);
  };

  function renderAssemblyBuildLabel(row: any) {
    return (
      <>
        <WorkOrderAction assemblyBuild={row} onAction={handleOperation} />
        {row.additionalData?.label ? (
          <Link href={row.additionalData.externalLink} target="_blank">
            {row.additionalData.label}
          </Link>
        ) : (
          <span>{`#${row.assemblyBuildId}`}</span>
        )}
      </>
    );
  }
  function renderAssemblyBuildStatus(row: any) {
    let color: any = 'default';
    switch (row.status as AssemblyBuildStatus) {
      case AssemblyBuildStatus.INSUFFICIENT_INVENTORY:
      case AssemblyBuildStatus.AUTOLOT_FAILED:
      case AssemblyBuildStatus.PENDING_API:
        color = 'error';
        break;
      case AssemblyBuildStatus.PROCESSING:
        color = 'info';
        break;
      case AssemblyBuildStatus.PENDING:
      case AssemblyBuildStatus.NEW:
        color = 'success';
        break;
    }
    return <Chip size="small" label={row.readableStatus} color={color} />;
  }

  return (
    <Fragment>
      <Grid container spacing={1} margin={1}>
        {Object.keys(statuses).map((status: string, index) => {
          return (
            <Grid item key={`status-${index}`}>
              <InfoCard
                caption={AssemblyBuildStatusReadableStatus[status as AssemblyBuildStatus]}
                number={statuses[status]}
                icon={Icons[status as AssemblyBuildStatus]}
                loading={loading}
              />
            </Grid>
          );
        })}
      </Grid>

      <SearchBox
        query={''}
        onQueryChange={setSearchText}
        disabled={loading}
        dateFilters={{ creationDate }}
        selectableFilters={selectableFilters}
        onFilterApplying={handleFilterChange}
        captions={{
          creationDate: 'Creation Date',
          shippingDate: 'Shipping Date',
          orderDate: 'Order Date',
          shipVia: 'Ship Via',
        }}
      />
      <Card>
        <CardHeader color="primary">
          <Grid container p={0} m={0} alignItems="center">
            <Grid item flexGrow={1}>
              <span>Inventory Management</span>
            </Grid>
            <Grid item alignItems="center">
              <Typography variant="caption">Last Update {moment(lastRefetch).format('HH:mm')}</Typography>
              <IconButton
                size="small"
                onClick={() => {
                  if (!loading) setNeedsRefresh(true);
                }}
              >
                {loading ? (
                  <CircularProgress size={24} style={{ color: 'white' }} />
                ) : (
                  <Refresh style={{ color: 'white' }} />
                )}
              </IconButton>
            </Grid>
          </Grid>
        </CardHeader>
        <CardBody>
          <Grid container>
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab label={PageModes.ASSEMBLY_BUILD_WORKORDER_OK} />
              <Tab label={PageModes.ASSEMBLY_BUILD_WORKORDER_TBD} />
              <Tab label={PageModes.ASSEMBLIES_PENDING_PRODUCTION} />
            </Tabs>
          </Grid>
          <Grid container>
            {pageMode == PageModes.ASSEMBLY_BUILD_WORKORDER_OK && (
              // <AssemblyBuildWorkorderTable
              //   assemblyBuilds={assemblyBuilds}
              //   timezone={auth?.account?.shipper?.timezone}
              // />
              <FilterableTable
                columns={[
                  {
                    field: 'assemblyBuildId',
                    caption: 'ID',
                    filterType: 'string',
                    sortable: true,
                    render: renderAssemblyBuildLabel,
                  },
                  {
                    field: 'name',
                    caption: 'Assembly',
                    filterType: 'string',
                    sortable: true,
                    render: (row) => strOrAlt(row.product.description, row.product.name, row.product.productId),
                  },
                  {
                    field: 'quantity',
                    caption: 'Count',
                    sortable: true,
                  },
                  {
                    field: 'readableStatus',
                    caption: 'Status',
                    filterType: 'values',
                    sortable: true,
                    render: renderAssemblyBuildStatus,
                  },
                ]}
                defaultSortBy="assemblyBuildId"
                data={assemblyBuildsOk}
                extendable
                renderExtended={(row) => <AssemblyBuildDetails assemblyBuild={row} />}
              />
            )}
            {pageMode == PageModes.ASSEMBLY_BUILD_WORKORDER_TBD && (
              <FilterableTable
                columns={[
                  {
                    field: 'assemblyBuildId',
                    caption: 'ID',
                    filterType: 'string',
                    sortable: true,
                    render: renderAssemblyBuildLabel,
                  },
                  {
                    field: 'name',
                    caption: 'Assembly',
                    filterType: 'string',
                    sortable: true,
                    render: (row) => strOrAlt(row.product.description, row.product.name, row.product.productId),
                  },
                  {
                    field: 'quantity',
                    caption: 'Count',
                    sortable: true,
                  },
                  {
                    field: 'readableStatus',
                    caption: 'Status',
                    filterType: 'values',
                    sortable: true,
                    render: renderAssemblyBuildStatus,
                  },
                ]}
                defaultSortBy="assemblyBuildId"
                data={assemblyBuildsTBD}
                extendable
                renderExtended={(row) => <AssemblyBuildDetails assemblyBuild={row} />}
              />
            )}
            {pageMode == PageModes.ASSEMBLIES_PENDING_PRODUCTION && (
              <ItemPendingBuildTable assemblyBuildItems={assemblyBuildItems} onAction={handleOperation} />
            )}
          </Grid>
        </CardBody>
      </Card>
      <LotsDialog
        onClose={handleAssemblyIemLotsClose}
        product={productItem}
        assemblyBuildItems={assemblyBuildItemsToEditLots}
        onSuccess={(updates) => handleOperation('update-assembly-items', updates)}
      />
      <ChangeLotOfAssemblyItemDialog
        onClose={handleChangeLotDialogClose}
        assemblyBuildItemIds={itemToChangeLot ? [itemToChangeLot.assemblyBuildItemId] : undefined}
        product={itemToChangeLot?.product}
        lotIdToChange={itemToChangeLot?.lotId}
        onNotify={setToast}
      />
    </Fragment>
  );
}

export default connector(InventoryManagement);
